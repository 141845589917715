import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Tab, Collapse, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";

const CompanyProfileDetails = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const [user, setUser] = useState('');
  const gContext = useContext(GlobalContext);
  const userId = params.get("id");
  const { user: loggedUser } = useContext(GlobalContext);
  const [loggedUserId, setLoggedUserId] = useState('');
  const [showMenuProfileId, setShowMenuProfileId] = useState(null);

  const imageBaseUrl = `${network.serverip}/images/`;

  const [collapsible, setCollapsible] = useState('');

  const toggleMenuButton = (id) => {
    setShowMenuProfileId(showMenuProfileId === id ? null : id)
  }

  const fetchUserDetails = async () => {
    try {
      const response = await axios.post(`${network.serverip}/api/profile-details-for-web`, {
        userId: userId,
        loggedUserId: loggedUserId,
      });

      if (response.data.success && isMountedRef.current) {
        setUser(response.data.userData);
        console.log('This is the fetched user details:', response.data.userData);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUser('');
        }
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUser('');
      }
      console.error('Error fetching user details:', error);
    }
  };


  useEffect(() => {
    if (loggedUser && loggedUser.id) {
      setLoggedUserId(loggedUser.id)
    }
  }, [loggedUser]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    isMountedRef.current = true;

    fetchUserDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId, loggedUserId]);

  const styles = CompanyProfileDetailsStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/dashboard-main');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-xl-12 col-lg-12 mb-5">
                <div className="bg-white rounded-4 shadow-9">
                  <div className="px-9 py-11 text-center border-bottom border-mercury">
                    <div className="w-100 d-flex justify-content-end">
                      {showMenuProfileId === user?.id &&
                        <>
                          {user?.isReported === 1 ?
                            (
                              <button
                                style={styles.reportButton}
                                className="font-size-4 text-gray"
                              >
                                <i className="fas fa-info-circle text-gray"></i>
                                <span className="text-gray ml-5">Already Reported</span>
                              </button>
                            ) : (
                              <button
                                onClick={() => { gContext.toggleReportProfileModal(user?.id) }}
                                style={styles.reportButton}
                                className="font-size-4 text-danger"
                              >
                                <i className="fas fa-info-circle text-danger"></i>
                                <span className="text-danger ml-5">Report</span>
                              </button>
                            )
                          }
                        </>
                      }
                      <button
                        onClick={() => { toggleMenuButton(user?.id) }}
                        style={styles.openMenuIcon}
                      >
                        <i className="fas fa-ellipsis-v ml-5"></i>
                      </button>
                    </div>

                    <button
                      className="mb-4"
                      onClick={() => gContext.toggleImageViewModal(user?.profile?.image)}
                      style={styles.imageViewButton}
                    >
                      <div className="d-flex justify-content-start mt-7">
                        <div style={styles.imageContainer}>
                          <div className="d-none d-sm-block">
                            <img
                              src={user?.profile?.image ?
                                imageBaseUrl + user.profile.image :
                                imageBaseUrl + "profile_default_photo.png"}
                              alt=""
                              style={styles.imageCircle}
                            />
                          </div>
                          <div className="d-block d-sm-none">
                            <img
                              src={user?.profile?.image ?
                                imageBaseUrl + user.profile.image :
                                imageBaseUrl + "profile_default_photo.png"}
                              className="circle-54"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </button>
                    <h4 className="mb-0 mt-9"
                    >
                      <p >
                        {user?.name}
                      </p>
                    </h4>
                    <p className="text-gray font-size-4 mb-8">
                      {user?.roleforapp?.name}
                    </p>
                  </div>

                  <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                    <div className="mt-10">
                      <h5 className="text-black-2 mb-8 font-size-5">Contact Info</h5>
                      {(user && (user?.profile?.street_address || user?.profile?.city || user?.profile?.state || user?.profile?.country || user?.profile?.pin_code)) ? (
                        <div className="mb-7">
                          <div className="row d-flex align-items-start">
                            <div className="col-1 d-flex justify-content-start">
                              <p className="font-size-4 mb-0">
                                <i className="fas fa-map-marker-alt text-success"></i>
                              </p>
                            </div>
                            <div className="col-10 d-flex justify-content-start">
                              <p className="font-size-4 mb-0 text-black-2">
                                <span>{user?.profile?.street_address}</span>
                                <br />
                                <span>
                                  {user?.profile?.city}
                                  {user?.profile?.state ? `, ${user?.profile?.state}` : ''}
                                </span>
                                <br />
                                <span>{user?.profile?.country}</span>
                                <br />
                                <span>{user?.profile?.pin_code}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {user && user?.email &&
                        <div className="mb-7">
                          <div className="row d-flex align-items-start">
                            <div className="col-1 d-flex justify-content-start">
                              <p className="font-size-4 mb-0">
                                <i className="fas fa-envelope text-success"></i>
                              </p>
                            </div>
                            <div className="col-10 d-flex justify-content-start">
                              <p
                                className="font-size-4 mb-0 text-black-2 text-truncate"
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                                aria-label={`Email: ${user?.email || 'N/A'}`}
                                title={`Email: ${user?.email || 'N/A'}`}
                              >
                                {user?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                      }

                      {user && user?.mobile_number &&
                        <div className="mb-7">
                          <div className="row d-flex align-items-start">
                            <div className="col-1 d-flex justify-content-start">
                              <p className="font-size-4 mb-0">
                                <i className="fas fa-phone-alt text-success"></i>
                              </p>
                            </div>
                            <div className="col-10 d-flex justify-content-start">
                              <p
                                className="font-size-4 mb-0 text-black-2"
                                aria-label={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                                title={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                              >
                                {user?.mobile_number}
                              </p>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default CompanyProfileDetails;

function CompanyProfileDetailsStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    imageCircle: {
      width: '12.5rem',
      height: '12.5rem',
      borderRadius: '50%',
      overflow: 'hidden',
      objectFit: 'cover',
    },
    imageContainer: {
      position: 'relative',
      display: 'flex',
    },
    openMenuIcon: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },
    reportButton: {
      position: 'absolute',
      right: '3.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }
  };

  return styles;
}



